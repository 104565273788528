import {Box, Button, Drawer, IconButton, Link, Popover, Typography} from "@mui/material";
import {Apps, ArrowDropDown, ArrowRight, BugReport, Checklist, DeveloperMode, DirectionsRun, EmojiObjects, Engineering, FactCheck, Menu as MenuIcon, School, SignLanguage} from "@mui/icons-material";
import React from "react";
import {Blue3, Grey6, Grey7} from "../theme/styles";
import css_self from "./css/Pages.module.scss";
import "./css/Pages.scss";
import "../components/Navigation/css/NavBar.scss";
import {useLocation, useNavigate} from "react-router-dom";
import Reference from "../components/APIDocsTools/Reference";
import APIExplorer from "../components/APIDocsTools/APIExplorer";
import GettingStarted from "../components/APIDocsTools/GettingStarted";
import Learn from "../components/APIDocsTools/Learn";
import Develop from "../components/APIDocsTools/Develop";
import UseCases from "../components/APIDocsTools/UseCases";
import KnownIssues from "../components/APIDocsTools/KnownIssues";
import ChangeHistory from "../components/APIDocsTools/ChangeHistory";
import useBreakpoint from "../util/useBreakpoint";
import HTTPHeaders from "../components/APIDocsTools/HTTPHeaders";
import Employees from "../components/APIDocsTools/Employees";
import EmployeesGet from "../components/APIDocsTools/EmployeesGet";
import Attachments from "../components/APIDocsTools/Attachments";
import AttachmentsGet from "../components/APIDocsTools/AttachmentsGet";
import AttachmentsPut from "../components/APIDocsTools/AttachmentsPut";
import Boms from "../components/APIDocsTools/Boms";
import BomsGet from "../components/APIDocsTools/BomsGet";
import DocumentTypes from "../components/APIDocsTools/DocumentTypes";
import DocumentTypesGet from "../components/APIDocsTools/DocumentTypesGet";
import Parts from "../components/APIDocsTools/Parts";
import PartsGet from "../components/APIDocsTools/PartsGet";
import PartRevisions from "../components/APIDocsTools/PartRevisions";
import PartRevisionsGet from "../components/APIDocsTools/PartRevisionsGet";
import RESTResourceTypes from "../components/APIDocsTools/RESTResourceTypes";
import ErrorCodes from "../components/APIDocsTools/ErrorCodes";
import SupportedHTTPMethods from "../components/APIDocsTools/SupportedHTTPMethods";
import SupportedMediaTypes from "../components/APIDocsTools/SupportedMediaTypes";
import SupportedDataTypes from "../components/APIDocsTools/SupportedDataTypes";
import Terminology from "../components/APIDocsTools/Terminology";
import URLPaths from "../components/APIDocsTools/URLPaths";
import CreateYourApplication from "../components/APIDocsTools/CreateYourApplication";
import DeprecatedRestResources from "../components/APIDocsTools/DeprecatedRestResources";
import Authenticate from "../components/APIDocsTools/Authenticate";
import RateLimitation from "../components/APIDocsTools/RateLimitation";
import PartsPost from "../components/APIDocsTools/PartsPost";
import ApprovalRequests from "../components/APIDocsTools/ApprovalRequests";
import ApprovalRequestsGet from "../components/APIDocsTools/ApprovalRequestsGet";
import ApprovalRequestsPost from "../components/APIDocsTools/ApprovalRequestsPost";
import QueryParameters from "../components/APIDocsTools/QueryParameters";
import ApprovalRequestsPut from "../components/APIDocsTools/ApprovalRequestsPut";
import Equipments from "../components/APIDocsTools/Equipments";
import EquipmentsGet from "../components/APIDocsTools/EquipmentsGet";
import EquipmentsPost from "../components/APIDocsTools/EquipmentsPost";
import PartsPut from "../components/APIDocsTools/PartsPut";
import Suppliers from "../components/APIDocsTools/Suppliers";
import SuppliersGet from "../components/APIDocsTools/SuppliersGet";
import SuppliersPost from "../components/APIDocsTools/SuppliersPost";
import AuditLogs from "../components/APIDocsTools/AuditLogs";
import AuditLogsGet from "../components/APIDocsTools/AuditLogsGet";
import Lots from "../components/APIDocsTools/Lots";
import LotsGet from "../components/APIDocsTools/LotsGet";
import LotsPost from "../components/APIDocsTools/LotsPost";
import LotsPut from "../components/APIDocsTools/LotsPut";
import PaperLHRs from "../components/APIDocsTools/PaperLHRs";
import PaperLHRsGet from "../components/APIDocsTools/PaperLHRsGet";
import PaperLHRsPost from "../components/APIDocsTools/PaperLHRsPost";
import PaperLHRsPut from "../components/APIDocsTools/PaperLHRsPut";
import PaperLHRRevisionsGet from "../components/APIDocsTools/PaperLHRRevisionsGet";
import PaperLHRRevisionsPost from "../components/APIDocsTools/PaperLHRRevisionsPost";
import PaperLHRRevisionsPut from "../components/APIDocsTools/PaperLHRRevisionsPut";

type MenuSelection = {id: string, target: EventTarget & HTMLButtonElement}
type ContentsSelection = Boolean;
type ContentsTypeSelection = "Category" | "List";

export default function ApiAndTools() {
    const [contentsState, setContentsState] = React.useState<ContentsSelection>(true);
    const [contentsType, setContentsType] = React.useState<ContentsTypeSelection>("Category");
    const [apiExplorerVisibility, setApiExplorerVisibility] = React.useState<ContentsSelection>(false);
    const [gettingStatedVisibility, setGettingStatedVisibility] = React.useState<ContentsSelection>(false);
    const [learnVisibility, setLearnVisibility] = React.useState<ContentsSelection>(false);
    const [developVisibility, setDevelopVisibility] = React.useState<ContentsSelection>(false);
    const [useCaseVisibility, setUseCaseVisibility] = React.useState<ContentsSelection>(false);
    const [knownIssues, setKnownIssues] = React.useState<ContentsSelection>(false);
    const [reference, setReference] = React.useState<ContentsSelection>(false);
    const [drawerVisibility, setDrawerVisibility] = React.useState<boolean>(false);

    const [menu, setMenu] = React.useState<MenuSelection | null>(null);

    const navigate = useNavigate();
    const location = useLocation();
    const breakpoint = useBreakpoint();

    function renderAPIByCategory(){
        return apiExplorerVisibility === true ? (
            <div style={{display: "flex", flexDirection: "column", marginTop: "5px", width: "100%"}}>
                <div style={{marginTop: "15px"}}>
                    <Link className={css_self.LinkCategory} onClick={() => {
                        navigate("/api-docs-tools/api-explorer/approval-requests");
                    }}>Approval Request (AR)</Link>
                </div>
                <div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/approval-requests-get");
                        }}>/t3/v1/approval_requests GET</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/approval-requests-post");
                        }}>/t3/v1/approval_requests POST</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/approval-requests-put");
                        }}>/t3/v1/approval_requests PUT</Link>
                    </div>
                </div>
                <div style={{marginTop: "15px"}}>
                    <Link className={css_self.LinkCategory} onClick={() => {
                        navigate("/api-docs-tools/api-explorer/attachments");
                    }}>Attachments</Link>
                </div>
                <div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/attachments-get");
                        }}>/t3/v1/attachments GET</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/attachments-post");
                        }}>/t3/v1/attachments POST</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/attachments-put");
                        }}>/t3/v1/attachments PUT</Link>
                    </div>
                </div>
                <div style={{marginTop: "15px"}}>
                    <Link className={css_self.LinkCategory} onClick={() => {
                        navigate("/api-docs-tools/api-explorer/audit-logs");
                    }}>Audit Logs</Link>
                </div>
                <div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/audit-logs-get");
                        }}>/t3/v1/audit_logs GET</Link>
                    </div>
                </div>
                <div style={{marginTop: "15px"}}>
                    <Link className={css_self.LinkCategory} onClick={() => {
                        navigate("/api-docs-tools/api-explorer/authenticate");
                    }}>Authenticate</Link>
                </div>
                <div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/authenticate-get");
                        }}>/t3/v1/authenticate GET</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/authenticate-post");
                        }}>/t3/v1/authenticate POST</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/authenticate-put");
                        }}>/t3/v1/authenticate PUT</Link>
                    </div>
                </div>
                <div style={{marginTop: "15px"}}>
                    <Link className={css_self.LinkCategory} onClick={() => {
                        navigate("/api-docs-tools/api-explorer/boms");
                    }}>Bill of Materials (BOM)</Link>
                </div>
                <div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/boms-get");
                        }}>/t3/v1/boms GET</Link>
                    </div>
                </div>
                <div style={{marginTop: "15px"}}>
                    <Link className={css_self.LinkCategory} onClick={() => {
                        navigate("/api-docs-tools/api-explorer/dam");
                    }}>DAM</Link>
                </div>
                <div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>/t3/v1/dams GET</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>/t3/v1/dams POST</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>/t3/v1/dams PUT</Typography>
                    </div>
                </div>
                <div style={{marginTop: "15px"}}>
                    <Link className={css_self.LinkCategory} onClick={() => {
                        navigate("/api-docs-tools/api-explorer/document-types");
                    }}>Document Type</Link>
                </div>
                <div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/document-types-get");
                        }}>/t3/v1/document-types GET</Link>
                    </div>
                </div>
                <div style={{marginTop: "15px"}}>
                    <Typography style={{fontSize: 12, fontWeight: 600, color: "#000000"}}>Dynamic Form</Typography>
                </div>
                <div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>/t3/v1/dynamic_forms GET</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>/t3/v1/dynamic_forms POST</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>/t3/v1/dynamic_forms PUT</Typography>
                    </div>
                </div>
                <div style={{marginTop: "15px"}}>
                    <Link className={css_self.LinkCategory} onClick={() => {
                        navigate("/api-docs-tools/api-explorer/employees");
                    }}>Employee</Link>
                </div>
                <div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/employees-get");
                        }}>/t3/v1/employees GET</Link>
                    </div>
                </div>
                <div style={{marginTop: "15px"}}>
                    <Typography style={{fontSize: 12, fontWeight: 600, color: "#000000"}}>Engineering Build</Typography>
                </div>
                <div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>/t3/v1/engineering_builds GET</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>/t3/v1/engineering_builds POST</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>/t3/v1/engineering_builds PUT</Typography>
                    </div>
                </div>
                <div style={{marginTop: "15px"}}>
                    <Typography style={{fontSize: 12, fontWeight: 600, color: "#000000"}}>Equipment</Typography>
                </div>
                <div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>
                            <Link className={css_self.Link3} onClick={() => {
                                navigate("/api-docs-tools/api-explorer/equipments-get");
                            }}>/t3/v1/equipments GET</Link>
                        </Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/equipments-post");
                        }}>/t3/v1/equipments POST</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/equipments-put");
                        }}>/t3/v1/equipments PUT</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/equipment-revisions-get");
                        }}>/t3/v1/equipment-revisions GET</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/equipment-revisions-post");
                        }}>/t3/v1/equipment-revisions POST</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/equipment-revisions-put");
                        }}>/t3/v1/equipment-revisions PUT</Link>
                    </div>
                </div>
                <div style={{marginTop: "15px"}}>
                    <Typography style={{fontSize: 12, fontWeight: 600, color: "#000000"}}>Equipment Specification</Typography>
                </div>
                <div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>equipment_specifications GET</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>equipment_specifications POST</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>equipment_specifications PUT</Typography>
                    </div>
                </div>
                <div style={{marginTop: "15px"}}>
                    <Typography style={{fontSize: 12, fontWeight: 600, color: "#000000"}}>FAI (First Article Inspection)</Typography>
                </div>
                <div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>fais GET</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>fais POST</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>fais PUT</Typography>
                    </div>
                </div>
                <div style={{marginTop: "15px"}}>
                    <Typography style={{fontSize: 12, fontWeight: 600, color: "#000000"}}>Form</Typography>
                </div>
                <div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>forms GET</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>forms POST</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>forms PUT</Typography>
                    </div>
                </div>
                <div style={{marginTop: "15px"}}>
                    <Typography style={{fontSize: 12, fontWeight: 600, color: "#000000"}}>LHR (Lot History Record)</Typography>
                </div>
                <div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>lhrs GET</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>lhrs POST</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>lhrs PUT</Typography>
                    </div>
                </div>
                <div style={{marginTop: "15px"}}>
                    <Typography style={{fontSize: 12, fontWeight: 600, color: "#000000"}}>LHRT (Lot History Record Template)</Typography>
                </div>
                <div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>lhrts GET</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>lhrts POST</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>lhrts PUT</Typography>
                    </div>
                </div>
                <div style={{marginTop: "15px"}}>
                    <Link className={css_self.LinkCategory} onClick={() => {
                        navigate("/api-docs-tools/api-explorer/lots");
                    }}>Lots</Link>
                </div>
                <div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/lots-get");
                        }}>/t3/v1/lots GET</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/lots-post");
                        }}>/t3/v1/lots POST</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/lots-put");
                        }}>/t3/v1/lots PUT</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/lot-revisions-get");
                        }}>/t3/v1/lot_revisions GET</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/lot-revisions-post");
                        }}>/t3/v1/lot_revisions POST</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/lot-revisions-put");
                        }}>/t3/v1/lot_revisions PUT</Link>
                    </div>
                </div>
                <div style={{marginTop: "15px"}}>
                    <Typography style={{fontSize: 12, fontWeight: 600, color: "#000000"}}>Maintenance Record</Typography>
                </div>
                <div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>maintenance_records GET</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>maintenance_records POST</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>maintenance_records PUT</Typography>
                    </div>
                </div>
                <div style={{marginTop: "15px"}}>
                    <Typography style={{fontSize: 12, fontWeight: 600, color: "#000000"}}>Material</Typography>
                </div>
                <div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>materials GET</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>materials POST</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>materials PUT</Typography>
                    </div>
                </div>
                <div style={{marginTop: "15px"}}>
                    <Link className={css_self.LinkCategory} onClick={() => {
                        navigate("/api-docs-tools/api-explorer/paper-lhrs");
                    }}>Paper LHR</Link>
                </div>
                <div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/paper-lhrs-get");
                        }}>/t3/v1/paper_lhrs GET</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/paper-lhrs-post");
                        }}>/t3/v1/paper_lhrs POST</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/paper-lhrs-put");
                        }}>/t3/v1/paper_lhrs PUT</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/paper-lhr-revisions-get");
                        }}>/t3/v1/paper_lhr_revisions GET</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/paper-lhr-revisions-post");
                        }}>/t3/v1/paper_lhr_revisions POST</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/paper-lhr-revisions-put");
                        }}>/t3/v1/paper_lhr_revisions PUT</Link>
                    </div>
                </div>
                <div style={{marginTop: "15px"}}>
                    <Typography style={{fontSize: 12, fontWeight: 600, color: "#000000"}}>
                        <Link className={css_self.LinkCategory} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/parts");
                        }}>Part</Link>
                    </Typography>
                </div>
                <div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/parts-get");
                        }}>/t3/v1/parts GET</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/parts-post");
                        }}>/t3/v1/parts POST</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/parts-put");
                        }}>/t3/v1/parts PUT</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/part-revisions-get");
                        }}>/t3/v1/part_revisions GET</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/part-revisions-post");
                        }}>/t3/v1/part_revisions POST</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/part-revisions-put");
                        }}>/t3/v1/part_revisions PUT</Link>
                    </div>
                </div>
                <div style={{marginTop: "15px"}}>
                    <Typography style={{fontSize: 12, fontWeight: 600, color: "#000000"}}>PI (Process Instruction)</Typography>
                </div>
                <div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>pis GET</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>pis POST</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>pis PUT</Typography>
                    </div>
                </div>
                <div style={{marginTop: "15px"}}>
                    <Typography style={{fontSize: 12, fontWeight: 600, color: "#000000"}}>PI (Process Instruction Instance)</Typography>
                </div>
                <div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>pi_instances GET</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>pi_instances POST</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>pi_instances PUT</Typography>
                    </div>
                </div>
                <div style={{marginTop: "15px"}}>
                    <Typography style={{fontSize: 12, fontWeight: 600, color: "#000000"}}>PO (Purchase Order) </Typography>
                </div>
                <div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>pos GET</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>pos POST</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>po PUT</Typography>
                    </div>
                </div>
                <div style={{marginTop: "15px"}}>
                    <Typography style={{fontSize: 12, fontWeight: 600, color: "#000000"}}>POAM (Purchase Order Approval Matrix)</Typography>
                </div>
                <div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>poamsGET</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>poams POST</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>poams PUT</Typography>
                    </div>
                </div>
                <div style={{marginTop: "15px"}}>
                    <Typography style={{fontSize: 12, fontWeight: 600, color: "#000000"}}>Receivable</Typography>
                </div>
                <div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>receivables GET</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>receivables POST</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>receivables PUT</Typography>
                    </div>
                </div>
                <div style={{marginTop: "15px"}}>
                    <Typography style={{fontSize: 12, fontWeight: 600, color: "#000000"}}>Record</Typography>
                </div>
                <div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>records GET</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>records POST</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>records PUT</Typography>
                    </div>
                </div>
                <div style={{marginTop: "15px"}}>
                    <Typography style={{fontSize: 12, fontWeight: 600, color: "#000000"}}>Supplier</Typography>
                </div>
                <div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/suppliers-get");
                        }}>/t3/v1/suppliers GET</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/suppliers-post");
                        }}>/t3/v1/suppliers POST</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/suppliers-put");
                        }}>/t3/v1/suppliers PUT</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/suppliers-revisions-get");
                        }}>/t3/v1/suppliers-revisions GET</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/suppliers-revisions-post");
                        }}>/t3/v1/suppliers-revisions POST</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/suppliers-revisions-put");
                        }}>/t3/v1/suppliers-revisions PUT</Link>
                    </div>
                </div>
                <div style={{marginTop: "15px"}}>
                    <Typography style={{fontSize: 12, fontWeight: 600, color: "#000000"}}>Training Curriculum</Typography>
                </div>
                <div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>training_curriculums GET</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>training_curriculums POST</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>training_curriculums PUT</Typography>
                    </div>
                </div>
                <div style={{marginTop: "15px"}}>
                    <Typography style={{fontSize: 12, fontWeight: 600, color: "#000000"}}>Work Order</Typography>
                </div>
                <div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>work_orders GET</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>work_orders POST</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>work_orders PUT</Typography>
                    </div>
                </div>
            </div>
        ) : null;
    }

    function renderAPIByList() {
        return apiExplorerVisibility === true ? (
            <div style={{display: "flex", flexDirection: "column", marginTop: "5px", width: "100%"}}>
                <div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/attachments-get");
                        }}>/t3/v1/attachments GET</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/attachments-put");
                        }}>/t3/v1/attachments PUT</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/audit-logs-get");
                        }}>/t3/v1/audit_logs GET</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/authenticate-get");
                        }}>/t3/v1/authenticate GET</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/authenticate-post");
                        }}>/t3/v1/authenticate POST</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/authenticate-put");
                        }}>/t3/v1/authenticate PUT</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/boms-get");
                        }}>/t3/v1/boms GET</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/approval-requests-get");
                        }}>/t3/v1/approval_requests GET</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/approval-requests-post");
                        }}>/t3/v1/approval_requests POST</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/approval-requests-put");
                        }}>/t3/v1/approval_requests PUT</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>/t3/v1/dams GET</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>/t3/v1/dams POST</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>/t3/v1/dams PUT</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/document-types-get");
                        }}>/t3/v1/document-types GET</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>/t3/v1/dynamic_forms GET</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>/t3/v1/dynamic_forms POST</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>/t3/v1/dynamic_forms PUT</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/employees-get");
                        }}>/t3/v1/employees GET</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>/t3/v1/engineering_builds GET</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>/t3/v1/engineering_builds POST</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>/t3/v1/engineering_builds PUT</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/equipments-get");
                        }}>/t3/v1/equipments GET</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/equipments-post");
                        }}>/t3/v1/equipments POST</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/equipments-put");
                        }}>/t3/v1/equipments PUT</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/equipment-revisions-get");
                        }}>/t3/v1/equipment_revisions GET</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/equipments-revisions-post");
                        }}>/t3/v1/equipment_revisions POST</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/equipments-revisions-put");
                        }}>/t3/v1/equipment_revisions PUT</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>equipment_specifications GET</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>equipment_specifications POST</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>equipment_specifications PUT</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>fais GET</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>fais POST</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>fais PUT</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>forms GET</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>forms POST</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>forms PUT</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>lhrs GET</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>lhrs POST</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>lhrs PUT</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>lhrts GET</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>lhrts POST</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>lhrts PUT</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/lots-get");
                        }}>/t3/v1/lots GET</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/lots-post");
                        }}>/t3/v1/lots POST</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/lots-put");
                        }}>/t3/v1/lots PUT</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/lot-revisions-get");
                        }}>/t3/v1/lot_revisions GET</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/lot-revisions-post");
                        }}>/t3/v1/lot_revisions POST</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/lot-revisions-put");
                        }}>/t3/v1/lot_revisions PUT</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>maintenance_records GET</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>maintenance_records POST</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>maintenance_records PUT</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>materials GET</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>materials POST</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>materials PUT</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/paper-lot-history-records-get");
                        }}>/t3/v1/paper_lhrs GET</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/paper-lot-history-records-post");
                        }}>/t3/v1/paper_lhrs POST</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/paper-lot-history-records-put");
                        }}>/t3/v1/paper_lhrs PUT</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/paper-lot-history-record-revisions-get");
                        }}>/t3/v1/paper_lhr_revisions GET</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/paper-lot-history-record-revisions-post");
                        }}>/t3/v1/paper_lhr_revisions POST</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/paper-lot-history-record-revisions-put");
                        }}>/t3/v1/paper_lhr_revisions PUT</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/parts-get");
                        }}>/t3/v1/parts GET</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/parts-post");
                        }}>/t3/v1/parts POST</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/parts-put");
                        }}>/t3/v1/parts PUT</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/part-revisions-get");
                        }}>/t3/v1/part-revisions GET</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/part-revisions-post");
                        }}>/t3/v1/part-revisions POST</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/part-revisions-put");
                        }}>/t3/v1/part-revisions PUT</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>pis GET</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>pis POST</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>pis PUT</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>pi_instances GET</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>pi_instances POST</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>pi_instances PUT</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>pos GET</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>pos POST</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>po PUT</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>poamsGET</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>poams POST</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>poams PUT</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>receivables GET</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>receivables POST</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>receivables PUT</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>records GET</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>records POST</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>records PUT</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/suppliers-get");
                        }}>/t3/v1/suppliers GET</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/suppliers-post");
                        }}>/t3/v1/suppliers POST</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/suppliers-put");
                        }}>/t3/v1/suppliers PUT</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/suppliers-revisions-get");
                        }}>/t3/v1/suppliers-revisions GET</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/suppliers-revisions-post");
                        }}>/t3/v1/suppliers-revisions POST</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Link className={css_self.Link3} onClick={() => {
                            navigate("/api-docs-tools/api-explorer/suppliers-revisions-put");
                        }}>/t3/v1/suppliers-revisions PUT</Link>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>training_curriculums GET</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>training_curriculums POST</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>training_curriculums PUT</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>work_orders GET</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>work_orders POST</Typography>
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "5px"}}>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>work_orders PUT</Typography>
                    </div>
                </div>
            </div>
        ) : null;
    }

    let aatMenu = (
        <div style={{display: "flex", flexDirection: "column", paddingLeft: "17px", marginRight: "20px"}}>
            <div style={{display: "flex", flexDirection: "column", marginTop: "30px", width: "100%"}}>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", borderBottom: "1px solid #DDDDDD"}}>
                    <div style={{marginTop: "-3px", marginRight: "3px"}}>
                        {apiExplorerVisibility ? (
                            <IconButton style={{height: "15px", width: "15px"}} onClick={() => setApiExplorerVisibility(!apiExplorerVisibility)} onMouseDown={e => e.preventDefault()}>
                                <ArrowDropDown fontSize="medium"/>
                            </IconButton>
                        ) : (
                            <IconButton style={{height: "15px", width: "15px"}} onClick={() => setApiExplorerVisibility(!apiExplorerVisibility)} onMouseDown={e => e.preventDefault()}>
                                <ArrowRight fontSize="medium"/>
                            </IconButton>
                        )}
                    </div>
                    <Link className={css_self.Link} onClick={() => {
                        setDrawerVisibility(false)
                        navigate("/api-docs-tools/api-explorer");
                    }}>API Explorer</Link>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", marginBottom: "5px", marginLeft: "56px"}}>
                        <Button className={css_self.ViewByButton} variant="text" onClick={() => setContentsType("Category")}>
                            <Typography style={{fontSize: 12, fontWeight: 400, color: Grey7, borderBottom: contentsType === "Category" ? "2px solid #1498D8" : "2px solid #FAFAFA"}}>Category</Typography>
                        </Button>
                        <Typography style={{marginLeft: "5px", marginRight: "5px", fontSize: 12, fontWeight: 400, color: Grey6}}>|</Typography>
                        <Button className={css_self.ViewByButton} variant="text" onClick={() => setContentsType("List")}>
                            <Typography style={{fontSize: 12, fontWeight: 400, color: Grey7, borderBottom: contentsType === "List" ? "2px solid #1498D8" : "2px solid #FAFAFA"}}>API List</Typography>
                        </Button>
                    </div>
                </div>
                {contentsType === "Category" ? (renderAPIByCategory()) : (renderAPIByList())}
            </div>
            <div style={{display: "flex", flexDirection: "column", marginTop: "30px", width: "100%"}}>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "100%", borderBottom: "1px solid #DDDDDD"}}>
                    <div style={{marginTop: "-3px", marginRight: "3px"}}>
                        {gettingStatedVisibility ? (
                            <IconButton style={{height: "15px", width: "15px"}} onClick={() => {
                                setDrawerVisibility(false);
                                setGettingStatedVisibility(!gettingStatedVisibility)
                            }} onMouseDown={e => e.preventDefault()}>
                                <ArrowDropDown fontSize="medium"/>
                            </IconButton>
                        ) : (
                            <IconButton style={{height: "15px", width: "15px"}} onClick={() => {
                                setDrawerVisibility(false);
                                setGettingStatedVisibility(!gettingStatedVisibility)
                            }} onMouseDown={e => e.preventDefault()}>
                                <ArrowRight fontSize="medium"/>
                            </IconButton>
                        )}
                    </div>
                    <Link className={css_self.Link} onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        setDrawerVisibility(false)
                        navigate("/api-docs-tools/getting-started");
                    }}>Getting Started</Link>
                </div>
                <div style={{marginTop: "5px", width: "100%"}}>
                    {gettingStatedVisibility ? (
                        <>
                            <div className={css_self.SubMenuDiv}>
                                <Button className={css_self.SubMenuButton} variant="text" onClick={() => {
                                    setDrawerVisibility(false);
                                    navigate("/api-docs-tools/getting-started/create-your-application")
                                }}>Create your application</Button>
                            </div>
                            <div className={css_self.SubMenuDiv}>
                                <Button className={css_self.SubMenuButton} variant="text" onClick={() => {
                                    setDrawerVisibility(false);
                                    navigate("/api-docs-tools")
                                }}>Build a demo app</Button>
                            </div>
                            <div className={css_self.SubMenuDiv}>
                                <Button className={css_self.SubMenuButton} variant="text" onClick={() => {
                                    setDrawerVisibility(false);
                                    navigate("/api-docs-tools")
                                }}>Using API Explorer</Button>
                            </div>
                            <div className={css_self.SubMenuDiv}>
                                <Button className={css_self.SubMenuButton} variant="text" onClick={() => {
                                    setDrawerVisibility(false);
                                    navigate("/api-docs-tools")
                                }}>Create basic requests</Button>
                            </div>
                            <div className={css_self.SubMenuDiv}>
                                <Button className={css_self.SubMenuButton} variant="text" onClick={() => {
                                    setDrawerVisibility(false);
                                    navigate("/api-docs-tools")
                                }}>Get your Client ID and Client Secret</Button>
                            </div>
                            <div className={css_self.SubMenuDiv}>
                                <Button className={css_self.SubMenuButton} variant="text" onClick={() => {
                                    setDrawerVisibility(false);
                                    navigate("/api-docs-tools")
                                }}>Privileges & authorization</Button>
                            </div>
                            <div className={css_self.SubMenuDiv}>
                                <Button className={css_self.SubMenuButton} variant="text" onClick={() => {
                                    setDrawerVisibility(false);
                                    navigate("/api-docs-tools")
                                }}>Change app settings</Button>
                            </div>
                            <div className={css_self.SubMenuDiv}>
                                <Button className={css_self.SubMenuButton} variant="text" onClick={() => {
                                    setDrawerVisibility(false);
                                    navigate("/api-docs-tools")
                                }}>Change your developer profile</Button>
                            </div>
                        </>
                    ) : null}
                </div>
            </div>
            <div style={{display: "flex", flexDirection: "column", marginTop: "30px", width: "100%"}}>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "100%", borderBottom: "1px solid #DDDDDD"}}>
                    <div style={{marginTop: "-3px", marginRight: "3px"}}>
                        {learnVisibility ? (
                            <IconButton style={{height: "15px", width: "15px"}} onClick={() => {
                                setLearnVisibility(!learnVisibility)
                            }} onMouseDown={e => e.preventDefault()}>
                                <ArrowDropDown fontSize="medium"/>
                            </IconButton>
                        ) : (
                            <IconButton style={{height: "15px", width: "15px"}} onClick={() => {
                                setLearnVisibility(!learnVisibility)
                            }} onMouseDown={e => e.preventDefault()}>
                                <ArrowRight fontSize="medium"/>
                            </IconButton>
                        )}
                    </div>
                    <Link className={css_self.Link} onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        setDrawerVisibility(false);
                        navigate("/api-docs-tools/learn");
                    }}>Learn</Link>
                </div>
                <div style={{marginTop: "5px", width: "100%"}}>
                    {learnVisibility ? (
                        <>
                            <div className={css_self.SubMenuDiv}>
                                <Button className={css_self.SubMenuButton} variant="text" onClick={() => {
                                    setDrawerVisibility(false);
                                    navigate("/api-docs-tools")
                                }}>Using a REST client</Button>
                            </div>
                            <div className={css_self.SubMenuDiv}>
                                <Button className={css_self.SubMenuButton} variant="text" onClick={() => {
                                    setDrawerVisibility(false);
                                    navigate("/api-docs-tools")
                                }}>Result sets</Button>
                            </div>
                            <div className={css_self.SubMenuDiv}>
                                <Button className={css_self.SubMenuButton} variant="text" onClick={() => {
                                    setDrawerVisibility(false);
                                    navigate("/api-docs-tools")
                                }}>Compound actions</Button>
                            </div>
                            <div className={css_self.SubMenuDiv}>
                                <Button className={css_self.SubMenuButton} variant="text" onClick={() => {
                                    setDrawerVisibility(false);
                                    navigate("/api-docs-tools")
                                }}>Framework versions</Button>
                            </div>
                            <div className={css_self.SubMenuDiv}>
                                <Button className={css_self.SubMenuButton} variant="text" onClick={() => {
                                    setDrawerVisibility(false);
                                    navigate("/api-docs-tools")
                                }}>Cross-Origin Resource Sharing</Button>
                            </div>
                            <div className={css_self.SubMenuDiv}>
                                <Button className={css_self.SubMenuButton} variant="text" onClick={() => {
                                    setDrawerVisibility(false);
                                    navigate("/api-docs-tools/learn/rate-limitation")
                                }}>Rate Limitation</Button>
                            </div>
                            <div className={css_self.SubMenuDiv}>
                                <Button className={css_self.SubMenuButton} variant="text" onClick={() => {
                                    setDrawerVisibility(false);
                                    navigate("/api-docs-tools/learn/query-parameters")
                                }}>Query Parameters</Button>
                            </div>
                        </>
                    ) : null}
                </div>
            </div>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", marginTop: "30px", width: "100%", borderBottom: "1px solid #DDDDDD"}}>
                <div style={{marginTop: "-3px", marginRight: "3px"}}>
                    {developVisibility ? (
                        <IconButton style={{height: "15px", width: "15px"}} onClick={() => setDevelopVisibility(!developVisibility)} onMouseDown={e => e.preventDefault()}>
                            <ArrowDropDown fontSize="medium"/>
                        </IconButton>
                    ) : (
                        <IconButton style={{height: "15px", width: "15px"}} onClick={() => setDevelopVisibility(!developVisibility)} onMouseDown={e => e.preventDefault()}>
                            <ArrowRight fontSize="medium"/>
                        </IconButton>
                    )}
                </div>
                <Link className={css_self.Link} onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    setDrawerVisibility(false);
                    navigate("/api-docs-tools/develop");
                }}>Develop</Link>
            </div>
            <div style={{display: "flex", flexDirection: "column", marginTop: "30px", width: "100%"}}>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "100%", borderBottom: "1px solid #DDDDDD"}}>
                    <div style={{marginTop: "-3px", marginRight: "3px"}}>
                        {useCaseVisibility ? (
                            <IconButton style={{height: "15px", width: "15px"}} onClick={() => setUseCaseVisibility(!useCaseVisibility)} onMouseDown={e => e.preventDefault()}>
                                <ArrowDropDown fontSize="medium"/>
                            </IconButton>
                        ) : (
                            <IconButton style={{height: "15px", width: "15px"}} onClick={() => setUseCaseVisibility(!useCaseVisibility)} onMouseDown={e => e.preventDefault()}>
                                <ArrowRight fontSize="medium"/>
                            </IconButton>
                        )}
                    </div>
                    <Link className={css_self.Link} onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        setDrawerVisibility(false);
                        navigate("/api-docs-tools/use-cases");
                    }}>Use Cases</Link>
                </div>
                <div style={{marginTop: "5px", width: "100%"}}>
                    {useCaseVisibility ? (
                        <>
                            <div className={css_self.SubMenuDiv}>
                                <Button className={css_self.SubMenuButton} variant="text" onClick={() => {
                                    setDrawerVisibility(false);
                                    navigate("/api-docs-tools")
                                }}>Document Control</Button>
                            </div>
                            <div className={css_self.SubMenuDiv}>
                                <Button className={css_self.SubMenuButton} variant="text" onClick={() => {
                                    setDrawerVisibility(false);
                                    navigate("/api-docs-tools")
                                }}>Equipment Management</Button>
                            </div>
                            <div className={css_self.SubMenuDiv}>
                                <Button className={css_self.SubMenuButton} variant="text" onClick={() => {
                                    setDrawerVisibility(false);
                                    navigate("/api-docs-tools")
                                }}>Supplier Management</Button>
                            </div>
                            <div className={css_self.SubMenuDiv}>
                                <Button className={css_self.SubMenuButton} variant="text" onClick={() => {
                                    setDrawerVisibility(false);
                                    navigate("/api-docs-tools")
                                }}>Purchase Orders</Button>
                            </div>
                            <div className={css_self.SubMenuDiv}>
                                <Button className={css_self.SubMenuButton} variant="text" onClick={() => {
                                    setDrawerVisibility(false);
                                    navigate("/api-docs-tools")
                                }}>Manufacturing Management</Button>
                            </div>
                            <div className={css_self.SubMenuDiv}>
                                <Button className={css_self.SubMenuButton} variant="text" onClick={() => {
                                    setDrawerVisibility(false);
                                    navigate("/api-docs-tools")
                                }}>Training Management</Button>
                            </div>
                        </>
                    ) : null}
                </div>
            </div>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", marginTop: "30px", width: "100%", borderBottom: "1px solid #DDDDDD"}}>
                <div style={{marginTop: "-3px", marginRight: "3px"}}>
                    {knownIssues ? (
                        <IconButton style={{height: "15px", width: "15px"}} onClick={() => setKnownIssues(!knownIssues)} onMouseDown={e => e.preventDefault()}>
                            <ArrowDropDown fontSize="medium"/>
                        </IconButton>
                    ) : (
                        <IconButton style={{height: "15px", width: "15px"}} onClick={() => setKnownIssues(!knownIssues)} onMouseDown={e => e.preventDefault()}>
                            <ArrowRight fontSize="medium"/>
                        </IconButton>
                    )}
                </div>
                <Link className={css_self.Link} onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    setDrawerVisibility(false);
                    navigate("/api-docs-tools/known-issues");
                }}>Known Issues</Link>
            </div>
            <div style={{display: "flex", flexDirection: "column", marginTop: "30px", width: "100%"}}>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "100%", borderBottom: "1px solid #DDDDDD"}}>
                    <div style={{marginTop: "-3px", marginRight: "3px"}}>
                        {reference ? (
                            <IconButton style={{height: "15px", width: "15px"}} onClick={() => setReference(!reference)} onMouseDown={e => e.preventDefault()}>
                                <ArrowDropDown fontSize="medium"/>
                            </IconButton>
                        ) : (
                            <IconButton style={{height: "15px", width: "15px"}} onClick={() => setReference(!reference)} onMouseDown={e => e.preventDefault()}>
                                <ArrowRight fontSize="medium"/>
                            </IconButton>
                        )}
                    </div>
                    <Link className={css_self.Link} onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        setDrawerVisibility(false);
                        navigate("/api-docs-tools/reference");
                    }}>Reference</Link>
                </div>
                <div style={{marginTop: "5px", width: "100%"}}>
                    {reference ? (
                        <>
                            <div className={css_self.SubMenuDiv}>
                                <Button className={css_self.SubMenuButton} variant="text" onClick={() => {
                                    setDrawerVisibility(false);
                                    navigate("/api-docs-tools/reference/http-headers")
                                }}>HTTP Headers</Button>
                            </div>
                            <div className={css_self.SubMenuDiv}>
                                <Button className={css_self.SubMenuButton} variant="text" onClick={() => {
                                    setDrawerVisibility(false);
                                    navigate("/api-docs-tools/reference/RestResourceTypes")
                                }}>REST Resource Types</Button>
                            </div>
                            <div className={css_self.SubMenuDiv}>
                                <Button className={css_self.SubMenuButton} variant="text" onClick={() => {
                                    setDrawerVisibility(false);
                                    navigate("/api-docs-tools/reference/error-codes")
                                }}>Error Codes</Button>
                            </div>
                            <div className={css_self.SubMenuDiv}>
                                <Button className={css_self.SubMenuButton} variant="text" onClick={() => {
                                    setDrawerVisibility(false);
                                    navigate("/api-docs-tools/reference/SupportedDataTypes")
                                }}>Supported Data Types</Button>
                            </div>
                            <div className={css_self.SubMenuDiv}>
                                <Button className={css_self.SubMenuButton} variant="text" onClick={() => {
                                    setDrawerVisibility(false);
                                    navigate("/api-docs-tools/reference/SupportedHTTPMethods")
                                }}>Supported HTTP Methods</Button>
                            </div>
                            <div className={css_self.SubMenuDiv}>
                                <Button className={css_self.SubMenuButton} variant="text" onClick={() => {
                                    setDrawerVisibility(false);
                                    navigate("/api-docs-tools")
                                }}>Supported Media Types</Button>
                            </div>
                            <div className={css_self.SubMenuDiv}>
                                <Button className={css_self.SubMenuButton} variant="text" onClick={() => {
                                    setDrawerVisibility(false);
                                    navigate("/api-docs-tools")
                                }}>Terminology</Button>
                            </div>
                            <div className={css_self.SubMenuDiv}>
                                <Button className={css_self.SubMenuButton} variant="text" onClick={() => {
                                    setDrawerVisibility(false);
                                    navigate("/api-docs-tools")
                                }}>REST API Versions and URL Paths</Button>
                            </div>
                            <div className={css_self.SubMenuDiv}>
                                <Button className={css_self.SubMenuButton} variant="text" onClick={() => {
                                    setDrawerVisibility(false);
                                    navigate("/api-docs-tools")
                                }}>Use JSON Web Token for Authorization</Button>
                            </div>
                            <div className={css_self.SubMenuDiv}>
                                <Button className={css_self.SubMenuButton} variant="text" onClick={() => {
                                    setDrawerVisibility(false);
                                    navigate("/api-docs-tools")
                                }}>Working with Attachments</Button>
                            </div>
                            <div className={css_self.SubMenuDiv}>
                                <Button className={css_self.SubMenuButton} variant="text" onClick={() => {
                                    setDrawerVisibility(false);
                                    navigate("/api-docs-tools")
                                }}>Working with Lookups</Button>
                            </div>
                            <div className={css_self.SubMenuDiv}>
                                <Button className={css_self.SubMenuButton} variant="text" onClick={() => {
                                    setDrawerVisibility(false);
                                    navigate("/api-docs-tools")
                                }}>Verify Resource Updates Using the ETag Value</Button>
                            </div>
                        </>
                    ) : null}
                </div>
            </div>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", marginTop: "30px", width: "100%", borderBottom: "1px solid #DDDDDD"}}>
                <Link className={css_self.Link} onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    navigate("/api-docs-tools/change-history");
                }}>Change History</Link>
            </div>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", marginTop: "30px", width: "100%", borderBottom: "1px solid #DDDDDD"}}>
                <Link className={css_self.Link} onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    navigate("/api-docs-tools/deprecated-rest-resources");
                }}>Deprecated REST Resources</Link>
            </div>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", marginTop: "30px", height: contentsType === "Category" ? "0px" : "800px", width: "100%"}}>
                &nbsp;
            </div>
        </div>
    )
    let contents = (
        <div>
            <Button className={css_self.PagesButton} variant="text" startIcon={<MenuIcon sx={{width: 28, height: 35, color: Grey6}}/>} onClick={() => setContentsState(!contentsState)}>
                <Typography style={{fontSize: 14, fontWeight: 400, color: Grey7}}>CONTENTS</Typography>
            </Button>
            {aatMenu}
        </div>
    );

    const toggleDrawer = (newOpen: boolean) => () => {
        setDrawerVisibility(newOpen);
    };

    if (breakpoint.getBreakPointName() !== "xl" && breakpoint.getBreakPointName() !== "lg") {
        contents = (
            <div>
                <Drawer style={{backgroundColor: "#FAFAFA"}} open={drawerVisibility} onClose={toggleDrawer(!drawerVisibility)}>
                    <div style={{marginTop: breakpoint.getBreakPointName() !== "xl" && breakpoint.getBreakPointName() !== "lg" ? "100px": "0px", paddingLeft: "5px", paddingTop: "2px"}}>
                        <Button className={css_self.PagesButton} variant="text" startIcon={<MenuIcon sx={{width: 28, height: 35, color: Grey6}}/>} onClick={() => setDrawerVisibility(!drawerVisibility)}>
                            <Typography style={{fontSize: 14, fontWeight: 400, color: Grey7}}>CONTENTS</Typography>
                        </Button>
                        {aatMenu}
                    </div>
                </Drawer>
            </div>
        )
    }

    return (
        <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", height: 52, backgroundColor: "#1498D8"}}>
                <div style={{paddingTop: "9px", paddingLeft: "20px"}}>
                    <Typography style={{fontSize: 24, fontWeight: 400, color: "#FFFFFF"}}>API Docs & Tools</Typography>
                </div>
                <div>
                    <IconButton className={css_self.DashboardMenuButton} onClick={(e) => setMenu({id: "apps", target: e.currentTarget})}>
                        <Apps/>
                    </IconButton>{menu?.id === "apps" ? (
                    <Popover anchorEl={menu.target}
                             anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                             transformOrigin={{vertical: -12, horizontal: "left"}}
                             open={true}
                             onClose={() => setMenu(null)}
                             TransitionProps={{
                                 onExited: () => setMenu(null),
                             }}
                             PaperProps={{
                                 elevation: 2,
                             }}
                    >
                        <Box sx={{p: 2, bgcolor: "background.paper", width: "375px", height: "375px"}}>
                            <div style={{display: "flex", flexDirection: "column", padding: "10px"}}>
                                <div style={{display: "flex", flexDirection: "row", alignContent: "flex-start"}}>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                        <IconButton className={css_self.AppMenuButton}
                                                    onClick={() => {
                                                        navigate("/api-docs-tools/api-explorer");
                                                    }}
                                        >
                                            <Engineering sx={{color: Blue3, width: 45, height: 45}}/>
                                        </IconButton>
                                        <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>API Explorer</Typography>
                                    </div>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                        <IconButton className={css_self.AppMenuButton}
                                                    onClick={() => {
                                                        navigate("/api-docs-tools/getting-started");
                                                    }}
                                        >
                                            <DirectionsRun sx={{color: Blue3, width: 45, height: 45}}/>
                                        </IconButton>
                                        <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Getting Started</Typography>
                                    </div>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                        <IconButton className={css_self.AppMenuButton}
                                                    onClick={() => {
                                                        navigate("/api-docs-tools/learn");
                                                    }}
                                        >
                                            <School sx={{color: Blue3, width: 45, height: 45}}/>
                                        </IconButton>
                                        <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Learn</Typography>
                                    </div>
                                </div>
                                <div style={{marginTop: "20px", display: "flex", flexDirection: "row", alignContent: "flex-start"}}>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                        <IconButton className={css_self.AppMenuButton}
                                                    onClick={() => {
                                                        navigate("/api-docs-tools/develop");
                                                    }}>
                                            <DeveloperMode sx={{color: Blue3, width: 45, height: 45}}/>
                                        </IconButton>
                                        <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Develop</Typography>
                                    </div>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                        <IconButton className={css_self.AppMenuButton}
                                                    onClick={() => {
                                                        navigate("/api-docs-tools/use-cases");
                                                    }}
                                        >
                                            <EmojiObjects sx={{color: Blue3, width: 45, height: 45}}/>
                                        </IconButton>
                                        <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Use Cases</Typography>
                                    </div>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                        <IconButton className={css_self.AppMenuButton}
                                                    onClick={() => {
                                                        navigate("/api-docs-tools/known-issues");
                                                    }}
                                        >
                                            <BugReport sx={{color: Blue3, width: 45, height: 45}}/>
                                        </IconButton>
                                        <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Known Issues</Typography>
                                    </div>
                                </div>
                                <div style={{marginTop: "20px", display: "flex", flexDirection: "row", alignContent: "flex-start"}}>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                        <IconButton className={css_self.AppMenuButton}
                                                    onClick={() => {
                                                        navigate("/api-docs-tools/reference");
                                                    }}
                                        >
                                            <Checklist sx={{color: Blue3, width: 45, height: 45}}/>
                                        </IconButton>
                                        <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Reference</Typography>
                                    </div>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                        <IconButton className={css_self.AppMenuButton}
                                                    onClick={() => {
                                                        navigate("/api-docs-tools/change-history");
                                                    }}
                                        >
                                            <FactCheck sx={{color: Blue3, width: 45, height: 45}}/>
                                        </IconButton>
                                        <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Change History</Typography>
                                    </div>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                        <IconButton className={css_self.AppMenuButton}
                                                    onClick={() => {
                                                        navigate("/api-docs-tools/deprecated-rest-resources");
                                                    }}
                                        >
                                            <SignLanguage sx={{color: Blue3, width: 45, height: 45}}/>
                                        </IconButton>
                                        <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Deprecated REST Resources</Typography>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </Popover>
                ) : null}
                </div>
            </div>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                {breakpoint.getBreakPointName() === "lg" || breakpoint.getBreakPointName() === "xl" ? (
                    <div style={{paddingLeft: "5px", width: contentsState ? "300px" : "39px", borderRight: "1px solid #DDDDDD", backgroundColor: "#FAFAFA", minWidth: contentsState ? "300px" : "39px", minHeight: "100vh"}}>
                        {contentsState ? contents : (<Button className={css_self.PagesButton} variant="text" startIcon={<MenuIcon sx={{width: 28, height: 35, color: Grey6}} onClick={() => setContentsState(!contentsState)}/>}/>)}
                    </div>
                ) : (
                    <div style={{paddingLeft: "5px", width: "48px", height: "100vh", borderRight: "1px solid #DDDDDD", backgroundColor: "#FAFAFA"}}>
                        {drawerVisibility ? contents : (<Button className={css_self.PagesButton} variant="text" startIcon={<MenuIcon sx={{width: 28, height: 35, color: Grey6}} onClick={() => setDrawerVisibility(!drawerVisibility)}/>}/>)}
                    </div>
                )}
                {location.pathname.toLowerCase() === "/api-docs-tools/api-explorer" ? <APIExplorer/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/learn" ? <Learn/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/learn/rate-limitation" ? <RateLimitation/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/learn/query-parameters" ? <QueryParameters/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/develop" ? <Develop/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/use-cases" ? <UseCases/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/known-issues" ? <KnownIssues/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/getting-started" ? <GettingStarted/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/getting-started/create-your-application" ? <CreateYourApplication/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/reference" ? <Reference/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/reference/http-headers" ? <HTTPHeaders/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/reference/rest-resource-types" ? <RESTResourceTypes/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/reference/error-codes" ? <ErrorCodes/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/reference/supported-data-types" ? <SupportedDataTypes/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/reference/supported-http-methods" ? <SupportedHTTPMethods/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/reference/supported-media-types" ? <SupportedMediaTypes/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/reference/terminology" ? <Terminology/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/reference/url-paths" ? <URLPaths/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/api-explorer/audit-logs" ? <AuditLogs/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/api-explorer/audit-logs-get" ? <AuditLogsGet/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/api-explorer/authenticate" ? <Authenticate/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/api-explorer/employees" ? <Employees/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/api-explorer/employees-get" ? <EmployeesGet/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/api-explorer/attachments" ? <Attachments/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/api-explorer/attachments-get" ? <AttachmentsGet/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/api-explorer/attachments-put" ? <AttachmentsPut/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/api-explorer/boms" ? <Boms/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/api-explorer/boms-get" ? <BomsGet/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/api-explorer/approval-requests" ? <ApprovalRequests/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/api-explorer/approval-requests-get" ? <ApprovalRequestsGet/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/api-explorer/approval-requests-post" ? <ApprovalRequestsPost/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/api-explorer/approval-requests-put" ? <ApprovalRequestsPut/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/api-explorer/document-types" ? <DocumentTypes/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/api-explorer/document-types-get" ? <DocumentTypesGet/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/api-explorer/parts" ? <Parts/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/api-explorer/parts-get" ? <PartsGet/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/api-explorer/parts-post" ? <PartsPost/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/api-explorer/parts-put" ? <PartsPut/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/api-explorer/part-revisions" ? <PartRevisions/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/api-explorer/part-revisions-get" ? <PartRevisionsGet/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/api-explorer/equipments" ? <Equipments/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/api-explorer/equipments-get" ? <EquipmentsGet/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/api-explorer/equipments-post" ? <EquipmentsPost/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/api-explorer/suppliers" ? <Suppliers/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/api-explorer/suppliers-get" ? <SuppliersGet/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/api-explorer/suppliers-post" ? <SuppliersPost/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/change-history" ? <ChangeHistory/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/deprecated-rest-resources" ? <DeprecatedRestResources/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/api-explorer/lots" ? <Lots/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/api-explorer/lots-get" ? <LotsGet/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/api-explorer/lots-post" ? <LotsPost/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/api-explorer/lots-put" ? <LotsPut/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/api-explorer/lots-revisions-get" ? <LotsGet/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/api-explorer/lots-revisions-put" ? <LotsPut/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/api-explorer/lots-revisions-post" ? <LotsPost/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/api-explorer/paper-lhrs" ? <PaperLHRs/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/api-explorer/paper-lhrs-get" ? <PaperLHRsGet/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/api-explorer/paper-lhrs-post" ? <PaperLHRsPost/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/api-explorer/paper-lhrs-put" ? <PaperLHRsPut/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/api-explorer/paper-lhr-revisions-get" ? <PaperLHRRevisionsGet/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/api-explorer/paper-lhr-revisions-put" ? <PaperLHRRevisionsPut/> : null}
                {location.pathname.toLowerCase() === "/api-docs-tools/api-explorer/paper-lhr-revisions-post" ? <PaperLHRRevisionsPost/> : null}
            </div>
        </div>
    )
}