import React from "react";

import Applet from "../../Applet";
import DeveloperCredentialAuthorizationTable from "../../../../components/RestManagement/DeveloperCredentialAuthorizationTable";

class DeveloperCredentialAuthorizationTableApplet extends Applet {

    render() {
        return <DeveloperCredentialAuthorizationTable applet={this}
                               pageSize={this.state.PageSize}
                               placement={this.state.Placement}
                               columns={this.state.Columns}
                               title="Authorizations"
                               enterpriseComponent={this.getEnterpriseComponent()}
                               sort={this.state.Sort}
                               menu={this.state.Menu}
                               rowHeight={this.state.RowHeight}
        />;
    }

    state= {
        Name: "DeveloperCredentialAuthorizationTableApplet",
        Menu: {
            options: [
                {
                    displayValue: "Refresh",
                    value: "Refresh",
                },
            ],
        },
        RowHeight: 60,
        PageSize: {
            "default": 25,
            "xs": 3,
            "sm": 3,
            "md": 10,
            "lg": 10,
            "lx": 10,
        },
        Placement: {
            "xl": [
                {
                    id: "RecordPointer",
                },
                {
                    id: "Entity",
                },
                {
                    id: "ReadPermission",
                },
                {
                    id: "CreatePermission",
                },
                {
                    id: "UpdatePermission",
                },
                {
                    id: "Actions",
                },
            ],
        },
        Columns: {
            "RecordPointer": {
                id: "RecordPointer",
                width: 25,
                cellRenderer: "Row Selection Indicator Cell",
                header: null,
                headerRenderer: "",
                headerTitle: "",
            },
            "Entity": {
                id: "Entity",
                width: 300,
                cellRenderer: "Entity Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "Entity",
            },
            "ReadPermission": {
                id: "ReadPermission",
                width: 175,
                cellRenderer: "Permission Cell",
                header: "enabled",
                headerAlign: "center",
                headerRenderer: "Standard Single Header",
                headerTitle: "Read Permission",
            },
            "CreatePermission": {
                id: "CreatePermission",
                width: 175,
                cellRenderer: "Permission Cell",
                header: "enabled",
                headerAlign: "center",
                headerRenderer: "Standard Single Header",
                headerTitle: "Create Permission",
            },
            "UpdatePermission": {
                id: "UpdatePermission",
                width: 175,
                cellRenderer: "Permission Cell",
                header: "enabled",
                headerAlign: "center",
                headerRenderer: "Standard Single Header",
                headerTitle: "Update Permission",
            },
            "Actions": {
                id: "Actions",
                width: 50,
                cellRenderer: "Action Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "",
                pinned: "right",
                hideable: false,
            }
        },
        Sort: [
            {
                "Identifier": 1,
                "Visible": true,
                "Default": true,
                "Options": [{
                    "Direction": "ASC",
                    "SortAttribute": "Entity",
                    "DisplayName": "Entity",
                }],
            },
        ]
    }
}

export default DeveloperCredentialAuthorizationTableApplet;
