import React from "react";
import "../Navigation/css/NavBar.scss";
import {Black} from "../../theme/styles";
import paperLotHistoryRecordRequestQBE1 from '../../json/paper_lot_history_record/paper-lhr-request-qbe-1.json';
import paperLotHistoryRecordRequestQBE1Result from '../../json/paper_lot_history_record/paper-lot-history-record-request-qbe-1-result.json';
import paperLotHistoryRecord001 from "../../json/paper_lot_history_record/paper-lot-history-record-001.json";
import paperLotHistoryRecord002 from "../../json/paper_lot_history_record/paper-lot-history-record-002.json";
import paperLotHistoryRecord003 from "../../json/paper_lot_history_record/paper-lot-history-record-003.json";
import paperLotHistoryRecord004 from "../../json/paper_lot_history_record/paper-lot-history-record-004.json";
import v1_PaperLotHistoryRecord_001 from "../../assets/v1.PaperLotHistoryRecord-001.png";
import v1_PaperLotHistoryRecord_002 from "../../assets/v1.PaperLotHistoryRecord-002.png";
import v1_PaperLotHistoryRecord_003 from "../../assets/v1.PaperLotHistoryRecord-003.png";
import v1_PaperLotHistoryRecord_004 from "../../assets/v1.PaperLotHistoryRecord-004.png";
import partRequestQBE from "../../assets/partRequestQBE1.png";
import ReactJson from 'react-json-view'


export default function PaperLHRsGet() {

    return (
        <div style={{paddingLeft: "20px", paddingRight: "30px"}}>
            <p style={{fontSize: "24px", fontWeight: "400", color: Black}}>Paper LHRs GET</p>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Overview</p>

            <p style={{fontSize: "14px", fontWeight: "400", color: Black, marginLeft: "20px"}}>
                In Enlil, Paper Lot History Records are documents with a document type group attribute equal to "PAPER_LHR". The Paper LHRs GET API allows for the retrieval of paper lot history record content.
            </p>
            <br/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Parameters</p>
            <ul style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                <li style={{marginTop: "20px"}}>Header Parameters
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>X-Enlil-AccessToken (Required String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The X-Enlil-AccessToken is obtained during the authentication process and expires after 90 days.</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>X-Enlil-RefreshToken (Optional String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The X-Enlil-RefreshToken is obtained during the authentication process and never expires. This token is provided is the caller wants a refreshed X-Enlil-AccessToken</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>idoc-response (Optional String: Default v1.PaperLotHistoryRecord-001)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The name of the IDOC being requested.</li>
                </li>
                <li style={{marginTop: "20px"}}>Basic Query Parameters
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>USID (Optional String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The primary key of the lot entity</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>DocumentID (Optional String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The ID of the paper lot history record, such as LHR-00969</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>DocumentAcronym (Optional String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The Acronym of the lot, such as "LOT-"</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>DocumentTypeName (Optional String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The Name of the document type, such as "PAPER_LHR"</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>Limit (Optional Integer: Default 40)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The number of rows to return in the primary integration component in the IDOC</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>Offset (Optional Integer: Default 0)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The number of the row to start with in the result set</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>Sort (Optional String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The column or columns to sort the results set with in the primary integration component in the IDOC. Example: [Column1: DESC, Column1: ASC]</li>
                </li>
                <li style={{marginTop: "20px"}}>Advanced Query Parameters</li>
                <li style={{marginTop: "20px", marginLeft: "20px"}}>QBE (Optional Base64 encoded string)</li>
                <li style={{marginTop: "10px", marginLeft: "40px"}}>If this parameter is provided, the Basic Query Parameters will be ignored.</li>
                <li style={{marginTop: "20px", marginLeft: "20px"}}>Examples</li>
                <li style={{marginTop: "20px", marginLeft: "40px"}}>In this example, a QBE is run against Paper Lot History Record, Paper Lot History Record Revision and DocumentType.</li>
                <br/><br/>
                <p style={{fontFamily: "Courier New", fontSize: "12px", marginLeft: "40px"}}>
                    <img src={partRequestQBE} alt={"Model"}/>
                </p>
                <span style={{marginLeft: "40px"}}>The request will be :</span><br/><br/>

                <p style={{fontFamily: "Courier New", fontSize: "12px", marginLeft: "40px"}}>
                    <ReactJson src={paperLotHistoryRecordRequestQBE1} collapseStringsAfterLength={75} collapsed={4}/>
                </p><br/><br/>
                <span style={{marginLeft: "40px"}}>The response will be :</span><br/><br/>

                <p style={{fontFamily: "Courier New", fontSize: "12px", marginLeft: "40px"}}>
                    <ReactJson src={paperLotHistoryRecordRequestQBE1Result} collapseStringsAfterLength={75} collapsed={4}/>
                </p>

            </ul>
            <br/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Examples</p>
            <p style={{fontSize: "14px", fontWeight: "400", color: Black, marginLeft: "20px"}}>
                Enlil provides several IDOC formats to retrieve attachment related data using the attachments GET API. For example, if the user executes this request:<br/><br/>

                <span style={{fontFamily: "Courier New", fontSize: "14px",}}>curl -G -d "USID=88888888-4444-4444-4444-121212121212&limit=5&offset=0" -H "idoc-response: v1.Lot-001" -H "X-Enlil-AccessToken: &#60;token&#62;" http://integrate.enlil.io/t3/v1/paper_lhrs</span>
                <br/><br/>
                The response will resemble:<br/><br/>

                <p style={{fontFamily: "Courier New", fontSize: "12px"}}>
                    <ReactJson src={paperLotHistoryRecord001} collapseStringsAfterLength={75} collapsed={4}/>
                </p>
            </p>
            <br/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Error Codes</p>
            <ul style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                <li style={{marginTop: "20px"}}>400 CC-SGWE-QW23 - Invalid parameter: Limit</li>
            </ul>
            <ul style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                <li style={{marginTop: "20px"}}>400 CC-NDJU-ZOOP - Invalid parameter: Offset</li>
            </ul>
            <br/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>IDOCs</p>
            <p style={{fontSize: "14px", fontWeight: "400", color: Black, marginLeft: "20px", lineHeight: "1.2"}}>
                <span style={{textDecoration: "underline"}}><b>v1.PaperLotHistoryRecord-001</b></span><br/><br/>
                <span style={{lineHeight: "1.5em"}}>v1.PaperLotHistoryRecord-001 is an IDOC designed to return information about paper paper lot history records only without any related information. v1.PaperLotHistoryRecord-001 is limited to a maximum of 40 records per request. To retrieve more, users are advised to paginate using the Limit and Offset query parameters. The scope of v1.PaperLotHistoryRecord-001 is highlighted below.</span>

                <p style={{fontSize: "12px", fontWeight: "400", color: Black, fontFamily: "Courier New"}}>
                    <img src={v1_PaperLotHistoryRecord_001} alt={"v1.PaperLotHistoryRecord-001"}/>
                </p>

                <p style={{fontSize: "12px", fontWeight: "400", color: Black, fontFamily: "Courier New"}}>
                    <ReactJson src={paperLotHistoryRecord001} collapseStringsAfterLength={75} collapsed={4}/>
                </p>
            </p>
            <br/>
            <p style={{fontSize: "14px", fontWeight: "400", color: Black, marginLeft: "20px"}}>
                <span style={{textDecoration: "underline"}}><b>v1.PaperLotHistoryRecord-002</b></span><br/><br/>
                <span style={{lineHeight: "1.5em"}}>v1.PaperLotHistoryRecord-002 is an IDOC designed to return a focused get of a single paper lot history record as specified in the USID parameter and all of its related information such as Document Type, Change Request, Attachment, Groups, etc. v1.PaperLotHistoryRecord-002 is limited to a maximum of 1 paper lot history record per request. The scope of v1.PaperLotHistoryRecord-002 is highlighted below.</span>

                <p style={{fontSize: "12px", fontWeight: "400", color: Black, fontFamily: "Courier New"}}>
                    <img src={v1_PaperLotHistoryRecord_002} alt={"v1.PaperLotHistoryRecord-002"}/>
                </p>

                <p style={{fontSize: "12px", fontWeight: "400", color: Black, fontFamily: "Courier New"}}>
                    <ReactJson src={paperLotHistoryRecord002} collapseStringsAfterLength={75} collapsed={4}/>
                </p>
            </p>
            <br/>
            <p style={{fontSize: "14px", fontWeight: "400", color: Black, marginLeft: "20px"}}>
                <span style={{textDecoration: "underline"}}><b>v1.PaperLotHistoryRecord-003</b></span><br/><br/>
                <span style={{lineHeight: "1.5em"}}>v1.PaperLotHistoryRecord-003 is an IDOC designed to return a focused get of a single paper lot history record as specified in the USID parameter and its related Attachment information. v1.PaperLotHistoryRecord-003 is limited to a maximum of 1 paper lot history record per request. The scope of v1.PaperLotHistoryRecord-003 is highlighted below.</span>

                <p style={{fontSize: "12px", fontWeight: "400", color: Black, fontFamily: "Courier New"}}>
                    <img src={v1_PaperLotHistoryRecord_003} alt={"v1.PaperLotHistoryRecord-003"}/>
                </p>

                <p style={{fontSize: "12px", fontWeight: "400", color: Black, fontFamily: "Courier New"}}>
                    <ReactJson src={paperLotHistoryRecord003} collapseStringsAfterLength={75} collapsed={4}/>
                </p>
            </p>
            <br/>
            <p style={{fontSize: "14px", fontWeight: "400", color: Black, marginLeft: "20px"}}>
                <span style={{textDecoration: "underline"}}><b>v1.PaperLotHistoryRecord-004</b></span><br/><br/>
                <span style={{lineHeight: "1.5em"}}>v1.PaperLotHistoryRecord-004 is an IDOC designed to return a focused get of a single paper lot history record as specified in the USID parameter and its related Paper Lot History Record Revision and Group information. v1.PaperLotHistoryRecord-004 is limited to a maximum of 1 paper lot history record per request. The scope of v1.PaperLotHistoryRecord-004 is highlighted below.</span>

                <p style={{fontSize: "12px", fontWeight: "400", color: Black, fontFamily: "Courier New"}}>
                    <img src={v1_PaperLotHistoryRecord_004} alt={"v1.PaperLotHistoryRecord-004"}/>
                </p>

                <p style={{fontSize: "12px", fontWeight: "400", color: Black, fontFamily: "Courier New"}}>
                    <ReactJson src={paperLotHistoryRecord004} collapseStringsAfterLength={75} collapsed={4}/>
                </p>
            </p>
        </div>
    )
}