import React from "react";

import Applet from "../../Applet";
import DeveloperCredentialAuthorizationDetailTable from "../../../../components/RestManagement/DeveloperCredentialAuthorizationDetailTable";

class DeveloperCredentialAuthorizationDetailTableApplet extends Applet {

    render() {
        return <DeveloperCredentialAuthorizationDetailTable applet={this}
                               pageSize={this.state.PageSize}
                               placement={this.state.Placement}
                               columns={this.state.Columns}
                               title="Authorization Details"
                               enterpriseComponent={this.getEnterpriseComponent()}
                               sort={this.state.Sort}
                               menu={this.state.Menu}
                               rowHeight={this.state.RowHeight}
        />;
    }

    state= {
        Name: "DeveloperCredentialAuthorizationDetailTableApplet",
        Menu: {
            options: [
                {
                    displayValue: "Refresh",
                    value: "Refresh",
                },
            ],
        },
        RowHeight: 60,
        PageSize: {
            "default": 5,
            "xs": 3,
            "sm": 3,
            "md": 10,
            "lg": 10,
            "lx": 10,
        },
        Placement: {
            "xl": [
                {
                    id: "RecordPointer",
                },
                {
                    id: "IDOC",
                },
                {
                    id: "Active",
                },
                {
                    id: "Actions",
                },
            ],
        },
        Columns: {
            "RecordPointer": {
                id: "RecordPointer",
                width: 25,
                cellRenderer: "Row Selection Indicator Cell",
                header: null,
                headerRenderer: "",
                headerTitle: "",
            },
            "IDOC": {
                id: "IDOC",
                width: 300,
                cellRenderer: "IDOC Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "IDOC",
                headerAlign: "left"
            },
            "Active": {
                id: "Active",
                width: 175,
                cellRenderer: "Active Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "Granted",
                headerAlign: "center"
            },
            "Actions": {
                id: "Actions",
                width: 50,
                cellRenderer: "Action Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "",
                pinned: "right",
                hideable: false,
            }
        },

    };
}

export default DeveloperCredentialAuthorizationDetailTableApplet;
