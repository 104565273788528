import React from "react";
import "../Navigation/css/NavBar.scss";
import {Black} from "../../theme/styles";
import paperLHREnterpriseObject from "../../assets/paperLotHistoryRecordEnterpriseObject.png";
import paperLHRRevisionEnterpriseObject from "../../assets/paperLotHistoryRecordRevisionEnterpriseObject.png";

export default function PaperLHRs() {

    return (
        <div style={{paddingLeft: "20px", paddingRight: "30px"}}>
            <p style={{fontSize: "24px", fontWeight: "400", color: Black}}>Paper LHR</p>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>REST APIs support the handling of Paper Lot History Records in Enlil.</p>
            <ul style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                <li style={{marginTop: "20px"}}>In Enlil, Paper Lot History Records are documents whose document type group attribute is set to "PAPER_LHR". This family of APIs allows for the retrieval, creation and updating of paper lot history records.</li>
            </ul>
            <br/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>The Enterprise Object Model for Paper Lot History Records is detailed below. Other Enlil entities (Equipment, Material, etc.) have a similar Enterprise Object Model.</p>
            <br/>
            <img src={paperLHREnterpriseObject} alt={"Model"}/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>The Enterprise Object Model for  Paper Lot History Record Revisions is detailed below. Other Enlil entities (Equipment Revision, Supplier Revision, etc.) have a similar Enterprise Object Model.</p>
            <br/>
            <img src={paperLHRRevisionEnterpriseObject} alt={"Model"}/>
        </div>
    )
}